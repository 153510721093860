module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-theme-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-theme-simplecast","short_name":"simplecast","start_url":"/","background_color":"#A085FF","theme_color":"#A085FF","display":"minimal-ui","icon":"src/images/icon.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"c1b1d6cce394acc31bb4cfbe8a00f057"},
    },{
      plugin: require('../node_modules/@vojtaholik/gatsby-theme-simplecast/gatsby-browser.js'),
      options: {"plugins":[],"simplecastApiSecret":"eyJhcGlfa2V5IjoiYTM4ZGQ5MWMwZjBmOGE3ZmQ5ZDRjNjcwYzdjZGZkMGMifQ==","markdownPath":"content/episodes","episodeSlug":"show"},
    },{
      plugin: require('../node_modules/gatsby-plugin-vercel/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
